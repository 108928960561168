import Text from '@/components/common/utils/Text'
import MarketsHead from '@/components/main/market/MarketsHead'
import { MOBILE_SIZE } from '@/components/layout/main/header/styles'
import MarketsTable from '@/components/main/market/MarketTable'
import RMarketTable from '@/components/main/market/RMarketTable'
import { useWindowSize } from '@/core/hooks/common/useWindowSize'
import { useMarketsTable } from '@/core/hooks/markets/useMarketsTable'
import { usePrices } from '@/core/hooks/markets/usePrices'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { deepCopy, formatNumber } from '@/core/utils/common'
import { ICON_URL, SOCKET_URL } from '@/core/constants/urls'
import Image from 'next/image'
import styled from 'styled-components'
import { IoMdArrowDropdown } from 'react-icons/io'
import { CgChevronLeft } from 'react-icons/cg'
import Toggler from '../../common/ui/Toggler'
import CustomUnderline from '@/components/common/ui/CustomUnderline'
import { useRouter } from 'next/navigation'
import { Helmet } from 'react-helmet'

const NewMarket = ({ main = 1, prime }) => {
    const { width } = useWindowSize()
    const {push} = useRouter()

    const [, updateState] = useState()
    const forceUpdate = useCallback(() => updateState({}), [])

    useEffect(() => {
        console.log('force')
        forceUpdate()
    }, [])

    const {
        coins,
        search,
        onSearch,
        currency,
        onCurrency,
        filter,
        onFilter,
        loading,
        categories,
        activeCategory,
        onCategoryChange,
        onFav,
    } = useMarketsTable()

    /* cards */
    const prices = usePrices()
    const cards = useMemo(() => {
        let newPrices = prices.filter((p) => p.id !== 'usdt')
        let profitCopy = deepCopy(newPrices)
        let hotCopy = deepCopy(newPrices)
        try {
            if (prices.length) {
                profitCopy.sort((a, b) =>
                    parseFloat(b.changes['24h'].changePresent) >
                    parseFloat(a.changes['24h'].changePresent)
                        ? 1
                        : -1
                )
                const profitest = profitCopy.slice(0, 3)
                const lostest = profitCopy
                    .slice(profitCopy.length - 3, profitCopy.length)
                    .reverse()
                const newest = profitCopy.slice(0, 3)
                // const newest = prices[prices.length - 1]
                const hotest = hotCopy
                    .sort((a, b) =>
                        (
                            currency === 'tooman'
                                ? a.toomanVolume > b.toomanVolume
                                : a.usdVolume > b.usdVolume
                        )
                            ? -1
                            : 1
                    )
                    .slice(0, 3)
                return [
                    { name: 'profitest', item: profitest },
                    { name: 'lostest', item: lostest },
                    { name: 'newest', item: newest },
                    { name: 'hottest', item: hotest },
                ]
            }
        } catch (_) {}
        return []
    }, [prices])

    return (
        <>
        <Helmet>
            <meta name='description' content="کنزیکس، بهترین صرافی برای خرید و فروش ارزهای دیجیتال با قیمت های رقابتی و امنیت بینظیر. همین حالا شروع کنید و به جمع سرمایه گذاران موفق بپیوندید!"/>
            <link rel="canonical" href="kenzx.io/market" />
        </Helmet>
        <div
            className={`w-[90%] mx-auto xl:max-w-[1240px] py-12 ${
                main ? 'mt-[70px]' : 'mt-[20px]'
            }`}>
            <div className='mb-6 flex flex-col gap-y-6  md:flex-row items-center md:justify-between md:items-stretch'>
                <CustomUnderline width={'100%'}>
                    <h2
                        className={
                            'text-[#374151] dark:text-white mb-4 md:mb-0 fontBold'
                        }>
                        <Text
                            tid={'markets-head'}
                            className={'md:text-3xl text-2xl'}
                        />
                    </h2>
                </CustomUnderline>
                {!prime && <Toggler onClick={(item) => onCurrency(item)} />}
            </div>
            {main ? (
                <div className={'overflow-x-auto overflow-y-hidden'}>
                    <Grid
                        className={
                            'grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-2'
                        }>
                        {cards.map((card, index) => {
                            // console.log('card', card)

                            return (
                                <div
                                key={index}
                                    className={
                                        'border rounded-lg dark:border-[#14253e] bg-white dark:bg-dark-primary px-4 py-2 w-full min-h-[132px] flex flex-col justify-between'
                                    }>
                                    <h3 className='mb-4'>
                                        <Text
                                            tid={card.name}
                                            className={
                                                'text-base dark:text-gray-300 text-gray-700'
                                            }
                                        />
                                    </h3>
                                    {card.item.map((item) => {
                                        let change
                                        try {
                                            change = parseFloat(
                                                item.changes['24h']
                                                    .changePresent
                                            )
                                        } catch (_) {}
                                        return (
                                            
                                            <div
                                            key={item.id}
                                            className='mb-4 last:mb-0 cursor-pointer hover:bg-light-hover dark:hover:bg-dark-hover p-1 rounded-md transition-all' onClick={() => push(`/${item.id}`)}>
                                                <div
                                                    className={
                                                        'flex items-center justify-around xl:justify-between'
                                                    }>
                                                    <div
                                                        className={
                                                            'flex justify-center items-center gap-2'
                                                        }>
                                                        <Image
                                                            src={
                                                                ICON_URL +
                                                                `assets/icon/${item.id}.png`
                                                            }
                                                            width={26}
                                                            height={26}
                                                        />
                                                        <div
                                                            className={
                                                                'flex items-center gap-x-1'
                                                            }>
                                                            {/* <Text
                                                                tid={item.fa}
                                                            /> */}
                                                            <span
                                                                className={
                                                                    'text-xs'
                                                                }>
                                                                <Text
                                                                    tid={item.id?.toUpperCase()}
                                                                />
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <span className='text-sm'>
                                                        {formatNumber(
                                                            item.value
                                                        )}
                                                    </span>
                                                    <div
                                                        className={
                                                            'flex items-center justify-center gap-1 text-xs'
                                                        }>
                                                        <div dir={'ltr'}>
                                                            <Text
                                                                tid={`${change}%`}
                                                                className={`fontBold ${
                                                                    change >= 0
                                                                        ? 'text-green-500'
                                                                        : 'text-red-500'
                                                                }`}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                            )
                        })}
                    </Grid>
                </div>
            ) : null}
            {prime && (
                <>
                    <MarketsHead
                        search={search}
                        onSearch={onSearch}
                        currency={currency}
                        onCurrency={onCurrency}
                        filter={filter}
                        onFilter={onFilter}
                    />

                    <div className={'flex items-center gap-2 flex-wrap mt-5'}>
                        <div
                            onClick={() => onCategoryChange({ name: 'همه' })}
                            className={`px-2 py-1 rounded-md  text-sm cursor-pointer ${
                                activeCategory.name === 'همه'
                                    ? 'dark:bg-white bg-dark-primary dark:text-dark-background text-primary-blue'
                                    : 'bg-white dark:bg-dark-primary'
                            }`}>
                            همه
                        </div>

                        <div
                            onClick={() => {
                                onCategoryChange({ name: 'مورد علاقه‌ها' })
                            }}
                            className={`px-2 py-1 rounded-md  text-sm cursor-pointer ${
                                activeCategory.name === 'مورد علاقه‌ها'
                                    ? 'dark:bg-white bg-dark-primary dark:text-dark-background text-primary-blue'
                                    : 'bg-white dark:bg-dark-primary'
                            }`}>
                            مورد علاقه‌ها
                        </div>

                        {categories?.map((item, index) => {
                            if (item.name === 'محبوب ترین') return <></>
                            return (
                                <div
                                    key={index}
                                    onClick={() => onCategoryChange(item)}
                                    className={`px-2 py-1 rounded-md  text-sm cursor-pointer ${
                                        activeCategory.name === item.name
                                            ? 'dark:bg-white bg-dark-primary dark:text-dark-background text-primary-blue'
                                            : 'bg-white dark:bg-dark-primary'
                                    }`}>
                                    {item.name}
                                </div>
                            )
                        })}

                    </div>
                </>
            )}
            {width > MOBILE_SIZE ? (
                <MarketsTable
                    coins={coins}
                    currency={currency}
                    loading={loading}
                    prime={prime}
                    onFav={onFav}
                />
            ) : (
                <RMarketTable
                    coins={coins}
                    currency={currency}
                    loading={loading}
                    prime={prime}
                    onFav={onFav}
                />
            )}
        </div>
        </>
    )
    
}

const Grid = styled.div``

export default NewMarket
