import Link from "next/link";
import {useRouter} from "next/router";


const LINK = ({ external, children, ...rest }) => {

    const { locale } = useRouter()

    return (
        <Link {...rest} locale={external ? 'fa' : locale }>
            {children}
        </Link>
    )
}

export default LINK
