import { FaRegStar, FaStar } from 'react-icons/fa'
import { getMainTheme } from '@/core/utils/theme'
import { useMainContext } from '@/core/contexts/main'
import { useRouter } from 'next/router'
import Text from '@/components/common/utils/Text'
import styled, { keyframes } from 'styled-components'
import { CiSearch } from 'react-icons/ci'
import { FiChevronDown } from 'react-icons/fi'
import { useRef, useState } from 'react'
import useClickOutside from '@/core/hooks/common/useClickOutside'
import { MdOutlineSort } from 'react-icons/md'
import { MOBILE_SIZE } from '@/components/layout/main/header/styles'
import { useWindowSize } from '@/core/hooks/common/useWindowSize'
import Toggler from '@/components/common/ui/Toggler'

const MarketsHead = ({
    search,
    onSearch,
    currency,
    onCurrency,
    filter,
    onFilter,
}) => {
    const { width } = useWindowSize()
    const { theme } = useMainContext()
    const { locale } = useRouter()

    const filters = ['newest', 'hottest', 'profitest', 'lostest']
    const [filterOpen, setFilterOpen] = useState(false)
    const filterRef = useRef()
    useClickOutside(filterRef, () => setFilterOpen(false))

    return (
        <div
            className={
                'w-full flex justify-between items-center mt-5 rounded-md bg-white dark:bg-dark-primary p-2'
            }>
            {width > MOBILE_SIZE && (
                <div
                    className={
                        'flex items-center md:flex-nowrap flex-wrap gap-2'
                    }>
                    {/* search field */}
                    <div className={'markets-header-btn'}>
                        <CiSearch
                            size={24}
                            color={getMainTheme(theme, locale).active}
                        />
                        <Input
                            value={search}
                            onChange={onSearch}
                            placeholder={locale === 'fa' ? 'جستجو' : 'search'}
                        />
                    </div>
                </div>
            )}

            <div className={'flex justify-center items-center gap-2'}>
                {/* currency field */}
                {/* <div
                    className={
                        'markets-header-btn w-[160px] md:w-[190px] justify-between'
                    }>
                    {['tooman', 'tether'].map((item) => (
                        <div
                            className={`${
                                currency === item &&
                                'bg-gray-300 dark:bg-gray-700'
                            } rounded w-[82px] flex items-center py-1 px-2 mx-1 transition`}
                            onClick={() => onCurrency(item)}>
                            <Text
                                tid={`to-${item}`}
                                className={'md:tex-sm text-xs'}
                            />
                        </div>
                    ))}
                </div> */}

                {/* filters field */}
                <div
                    ref={filterRef}
                    className={
                        'markets-header-btn md:min-w-[160px] min-w-[150px] justify-between text-sm relative'
                    }
                    onClick={() => setFilterOpen((state) => !state)}>
                    {width > MOBILE_SIZE && <MdOutlineSort size={22} />}
                    <Text tid={filter} />
                    <FiChevronDown
                        size={18}
                        className={`${
                            filterOpen ? 'rotate-180' : 'rotate-0'
                        } transition mx-2`}
                    />
                    {filterOpen && (
                        <Wrapper className={'shadow'}>
                            {filters.map((item) => (
                                <div
                                    className={`w-full p-1 rounded hover:bg-gray-300 dark:hover:bg-gray-700 my-[2px] ${
                                        filter === item &&
                                        'bg-green-300 text-dark-background'
                                    }`}
                                    onClick={() => onFilter(item)}>
                                    <Text
                                        tid={item}
                                        className={'md:text-sm text-xs'}
                                    />
                                </div>
                            ))}
                        </Wrapper>
                    )}
                </div>

                <Toggler onClick={(item) => onCurrency(item)} prime />
            </div>
        </div>
    )
}

const Input = styled.input`
    background: transparent;
    border: none;
    outline: none;
    width: 180px;
    height: 32px;
    color: ${(props) => props.theme.primary};
    margin: 0 4px;
    font-size: 14px;
`

const anim = keyframes`
  0% {
    top: 0;
    opacity: 0;
  }
  100% {
    top: 48px;
    opacity: 1;
  }
`

const Wrapper = styled.div`
    width: 100%;
    padding: 10px;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    background: ${(props) => props.theme.mainBg};
    animation: ${anim} 0.5s forwards;
    z-index: 101;
`

export default MarketsHead
