import { useOtc } from '@/components/main/otc/useOtc'
import { createContext, useContext } from 'react'

const TradeModeContext = createContext()

export const useTradeModeContext = () => useContext(TradeModeContext)

const TradeModeContextProvider = ({ children }) => {
    const { type, setType } = useOtc()

    return (
        <TradeModeContext.Provider value={{ type, setType }}>
            {children}
        </TradeModeContext.Provider>
    )
}

export default TradeModeContextProvider
