import Text from '@/components/common/utils/Text'
import { FaRegStar, FaStar } from 'react-icons/fa'
import { getMainTheme } from '@/core/utils/theme'
import { useRouter } from 'next/router'
import Image from 'next/image'
import { ICON_URL, SOCKET_URL } from '@/core/constants/urls'
import { formatNumber } from '@/core/utils/common'
import { ClipLoader } from 'react-spinners'
import { useEffect, useRef, useState } from 'react'
import MarketsBtn from '@/components/common/buttons/MarketBtn'
import { useOtc } from '../otc/useOtc'
import { Flex } from '@/styles/styled'
import { HiArrowDown, HiArrowUp } from 'react-icons/hi'
import styled from 'styled-components'
import { useTradeMode } from '@/core/hooks/markets/useTradeMode'
import { useMainContext } from '@/core/contexts/main'
import { useTradeModeContext } from '@/core/contexts/trade'
import { MainButton } from '@/components/common/buttons/MainButton'
import { useWindowSize } from '@/core/hooks/common/useWindowSize'
import Link from 'next/link'

const MarketsTable = ({ coins, currency, loading, prime, onFav }) => {
    const [limitCoins, setLimitCoins] = useState(7)

    const { width } = useWindowSize()

    const { locale, push } = useRouter()

    const headers = [
        'coins',
        'buy-price',
        'sell-price',
        'daily-change',
        'volume-price',
        'chart',
        'trade',
    ]

    const favRefs = useRef([])
    const onRowClick = (e, idx) => {
        if (!favRefs.current[idx]?.contains(e.target)) {
            // push(`/${coins[idx].id}`)
            window.open(`/${coins[idx].id}`, '_blank');
        }
      
    }

    const handleLimitCoinsChange = () => {
        setLimitCoins((currentLimit) => (currentLimit === 7 ? coins.length : 7))
    }

    const seeMoreBtnText =
        limitCoins === 7 ? (
            <>
                <Text tid='see-more' />
                <HiArrowDown />
            </>
        ) : (
            <>
                <Text tid='see-less' />
                <HiArrowUp />
            </>
        )

    return (
        <div className={`text-white rounded-lg w-full mt-5`}>
            {loading ? (
                <div
                    className={
                        'w-full h-[220px] flex justify-center items-center'
                    }>
                    <ClipLoader size={38} color={getMainTheme().active} />
                </div>
            ) : (
                <table
                    className={
                        'w-full text-tiny border-separate border-spacing-y-2'
                    }>
                    {width > 768 && (
                        <thead>
                            <tr
                                className={`bg-none text-black dark:text-white fontBold`}>
                                {headers.map((item, idx) => (
                                    <th
                                        key={item}
                                        className={`${
                                            idx === headers.length - 1 &&
                                            'hidden'
                                        } ${idx === 0 && 'ps-4'}`}>
                                        <Text tid={item} />
                                    </th>
                                    
                                ))}
                            </tr>
                            
                        </thead>
                    )}

                    <tbody
                        className={`relative ${
                            !coins.length && 'h-[300px]'
                        } rounded-md overflow-hidden`}>
                        {coins?.length ? (
                            coins?.map((coin, idx) => {
                                const Star = coin.fav ? FaStar : FaRegStar
                                let change
                                try {
                                    change = parseFloat(
                                        coin.changes['24h'].changePresent
                                    )
                                } catch (_) {}

                                if (idx < limitCoins || prime)
                                    return (

                                        <tr
                                            key={coin.id}
                                            className={`dark:bg-blue-primary-dark hover:dark:bg-[#19295c] hover:drop-shadow-lg bg-white text-black dark:text-white cursor-pointer transition fontBold`}
                                            onClick={(e) => onRowClick(e, idx)}>
                                            <td className='rounded-s-lg' >
                                        <Link href={`/${coin.id}`} passHref target='_blank'> 

                                                <div
                                                
                                                    className={
                                                        'flex gap-x-4 items-center ps-4'
                                                    }>
                                                    <span
                                                        className={`${
                                                            coin.fav
                                                                ? 'text-yellow-500'
                                                                : 'text-gray-500 dark:text-gray-dark'
                                                        } cursor-pointer`}
                                                        onClick={(e) => {
                                                            e.stopPropagation()
                                                            onFav(coin.id)
                                                        }}>
                                                        <FaStar size={18} />
                                                    </span>
                                                    <Image
                                                        alt={coin.id}
                                                        width={32}
                                                        height={32}
                                                        src={
                                                            ICON_URL +
                                                            `assets/icon/${coin.id}.png`
                                                        }
                                                        
                                                    />
                                                   
                                                    <div
                                                        className={
                                                            'flex gap-x-4'
                                                        }>
                                                        <Text tid={coin.fa} />
                                                        <Text tid={coin.name} />
                                                    </div>
                                                </div>
                                                </Link>
                                            </td>
                                            <td>
                                        <Link href={`/${coin.id}`} passHref target='_blank'> 

                                                <Text
                                                    tid={formatNumber(
                                                        currency === 'tether'
                                                            ? coin.value
                                                            : coin.buy,
                                                        {
                                                            type:
                                                                currency ===
                                                                'tether'
                                                                    ? coin.id
                                                                    : 'irt',
                                                            coin: coin.id,
                                                        }
                                                    )}
                                                />
                                                  </Link>
                                            </td>
                                            <td>
                                        <Link href={`/${coin.id}`} passHref target='_blank'> 

                                                <Text
                                                    tid={formatNumber(
                                                        currency === 'tether'
                                                            ? coin.value
                                                            : coin.sell,
                                                        {
                                                            type:
                                                                currency ===
                                                                'tether'
                                                                    ? coin.id
                                                                    : 'irt',
                                                            coin: coin.id,
                                                        }
                                                    )}
                                                />
                                                  </Link>
                                            </td>
                                            <td dir={'ltr'}>
                                        <Link href={`/${coin.id}`} passHref target='_blank'> 

                                                <Text
                                                    tid={`${change} %`}
                                                    className={`text-${
                                                        change >= 0
                                                            ? 'success'
                                                            : 'danger'
                                                    } `}
                                                />
                                                  </Link>
                                            </td>
                                            <td>
                                        <Link href={`/${coin.id}`} passHref target='_blank'> 

                                                <span>
                                                    {formatNumber(
                                                        currency === 'tether'
                                                            ? coin.usdVolume
                                                            : coin.toomanVolume,
                                                        {
                                                            type:
                                                                currency ===
                                                                'tether'
                                                                    ? 'usdt'
                                                                    : 'irt',
                                                        }
                                                    )}
                                                </span>
                                                </Link>
                                            </td>

                                            <td>
                                        <Link href={`/${coin.id}`} passHref target='_blank'> 

                                                <Image
                                                    alt={coin.id}
                                                    width={128}
                                                    height={32}
                                                    src={
                                                        ICON_URL +
                                                        `graphs/${coin.id}.svg`
                                                    }
                                                />
                                                  </Link>
                                            </td>
                                            <td className='rounded-e-lg'>
                                        <Link href={`/${coin.id}`} passHref target='_blank'> 

                                                <MarketsBtn
                                                    tid={'trade'}
                                                    className='bg-gray-dark hover:bg-blue-primary hover:text-white hover:dark:text-black dark:bg-blue-primary hover:dark:bg-blue-secondary fontNormal'
                                                />
                                            </Link>   
                                            </td>

                                        </tr>

                                    )
                            })
                        ) : (
                            <div
                                className={
                                    'absolute left-[50%] translate-x-[-50%] top-[40px]'
                                }>
                                <Image
                                    src={'/assets/images/noData.svg'}
                                    alt={'noData'}
                                    width={240}
                                    height={240}
                                />
                            </div>
                        )}
                    </tbody>
                </table>
            )}

            {!prime && (
                <Flex fw margin={'2rem 0 0'}>
                    <MoreButton
                        onClick={handleLimitCoinsChange}
                        className='!bg-blue-primary'>
                        {seeMoreBtnText}
                    </MoreButton>
                </Flex>
            )}
        </div>
    )
}

const MoreButton = styled(MainButton)`
    display: flex;
    align-items: center;
    gap: 1rem;
    padding: 0.5rem 0.8rem;
    font-size: 0.75rem;
`

export default MarketsTable
