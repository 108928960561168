import Text from '@/components/common/utils/Text'
import { useState } from 'react'

export default function Toggler({ onClick, prime = false }) {
    const [baseCurrency, setBaseCurrency] = useState('tooman')

    const handleToggle = (item) => {
        if (item === baseCurrency) return

        setBaseCurrency((curr) => (curr === 'tooman' ? 'tether' : 'tooman'))
        onClick(item)
    }

    const renderedOptions = ['tooman', 'tether'].map((item) => (
        <div
            key={item}
            className={`w-1/2 rounded-full ${
                !prime ? 'py-4 md:py-2' : 'py-3 m-0.5'
            } text-base md:text-lg ${
                baseCurrency === item
                    ? prime
                        ? 'bg-[#4961C9] text-white'
                        : 'bg-blue-primary-dark text-white'
                    : null
            } flex items-center justify-center`}
            onClick={handleToggle.bind(null, item)}>
            <Text tid={`to-${item}`} className={'md:text-sm text-xs'} />
        </div>
    ))

    return (
        <div
            className={`border ${
                !prime
                    ? 'max-[480px]:w-full w-1/3 md:w-[144px] bg-blue-primary border-blue-primary text-white'
                    : 'w-40 border-[#4961C9] text-dark-primary dark:text-white'
            } rounded-full flex justify-between cursor-pointer`}>
            {renderedOptions}
        </div>
    )
}
