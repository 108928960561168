import {useCoinCategories, useMarketVolumes} from "@/core/services/react-query/otc";
import {useSocketContext} from "@/core/contexts/socket";
import {useMemo} from "react";
import {deepCopy} from "@/core/utils/common";


export const usePrices = () => {


    const { pricesList } = useSocketContext()
    const { data: categories } = useCoinCategories()
    const { data: volumes } = useMarketVolumes()

    const prices = useMemo(() => {
        let temp = []
        pricesList?.forEach(p => {
            const newP = deepCopy(p)
            newP.categories = categories?.filter(c => c.symbols.includes(newP.id))
            const vol = volumes?.find(v => v.coin === newP.id)
            let toomanVolume = 0
            let usdVolume = 0
            if (!!vol) {
                toomanVolume = vol.value
                usdVolume = toomanVolume / newP.value
            }
            newP.toomanVolume = toomanVolume
            newP.usdVolume = usdVolume
            temp.push(newP)
        })
        return temp
    }, [categories, pricesList, volumes])

    return prices
}
