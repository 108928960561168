import { useMemo, useState } from 'react'
import { useSocketContext } from '@/core/contexts/socket'
import { getCookie, setCookie } from 'cookies-next'
import { usePrices } from '@/core/hooks/markets/usePrices'
import { useCoinCategories } from '@/core/services/react-query/otc'
import { deepCopy } from '@/core/utils/common'
import { useOtc } from '@/components/main/otc/useOtc'

const FAV_KEY = 'market-favs'
export const useMarketsTable = () => {
    const prices = usePrices()
    const [search, setSearch] = useState('')
    const [filter, setFilter] = useState('newest')
    const [currency, setCurrency] = useState('tooman') // tooman - tether

    const [favs, setFavs] = useState(
        getCookie(FAV_KEY) ? JSON.parse(getCookie(FAV_KEY)) : []
    )

    // categories
    const { data: categories } = useCoinCategories()
    const [activeCategory, setActiveCategory] = useState({ name: 'همه' })
    const onCategoryChange = (cat) => {
        setActiveCategory(cat)
    }

    const [loading, setLoading] = useState(true)
    const coins = useMemo(() => {
        let temp = deepCopy(prices)

        /* categories */
        if (
            activeCategory.name !== 'همه' &&
            activeCategory.name !== 'مورد علاقه‌ها'
        ) {
            temp = temp.filter((p) => activeCategory.symbols.includes(p.id))
        }

        /* search filter */
        if (search) {
            temp = temp.filter(
                (d) =>
                    d.id.includes(search) ||
                    d.name.includes(search) ||
                    d.fa.includes(search)
            )
        }

        /* fav filter */
        const onlyFav = activeCategory.name === 'مورد علاقه‌ها'
        if (prices?.length) {
            const favCoins = []
            for (const _coin of temp) {
                if (favs?.includes(_coin.id))
                    favCoins.push({ ..._coin, fav: true })
                else favCoins.push({ ..._coin, fav: false })
            }
            temp = [...favCoins]
            if (onlyFav) temp = temp.filter((d) => d.fav)
        }

        /* sort */
        try {
            if (filter === 'newest') temp.reverse()
            if (filter === 'hottest')
                temp.sort((a, b) =>
                    (
                        currency === 'tooman'
                            ? a.toomanVolume > b.toomanVolume
                            : a.usdVolume > b.usdVolume
                    )
                        ? -1
                        : 1
                )
            if (filter === 'profitest')
                temp.sort((a, b) =>
                    parseFloat(b.changes['24h'].changePresent) >
                    parseFloat(a.changes['24h'].changePresent)
                        ? 1
                        : -1
                )
            if (filter === 'lostest')
                temp.sort((a, b) =>
                    parseFloat(a.changes['24h'].changePresent) >
                    parseFloat(b.changes['24h'].changePresent)
                        ? 1
                        : -1
                )
            if (filter === 'favest') {
                const favCat = categories.find((c) => c.name === 'محبوب ترین')
                const newList = []
                favCat?.symbols.forEach((s) => {
                    const c = temp.find((x) => x.id === s)
                    newList.push(c)
                })
                temp = deepCopy(newList)
            }
        } catch (_) {}

        if (prices.length && loading) setLoading(false)
        return temp
    }, [prices, search, filter, activeCategory, favs])

    const onSearch = (e) => {
        const value = e?.target?.value
        setSearch(value)
    }

    const onCurrency = (curr) => {
        if (currency !== curr) setCurrency(curr)
    }

    const onFilter = (item) => {
        setFilter((state) => {
            if (state === item) return ''
            else return item
        })
    }
console.log("coin", coins);

    const onFav = (id) => {
        let temp = [...favs]
        if (favs?.includes(id)) temp = favs.filter((d) => d !== id)
        else temp.push(id)
        setFavs(temp)
        setCookie(FAV_KEY, JSON.stringify(temp))
    }

    return {
        coins,
        search,
        onSearch,
        currency,
        onCurrency,
        filter,
        onFilter,
        loading,
        categories,
        activeCategory,
        onCategoryChange,
        onFav,
    }
}
