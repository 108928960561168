import { useState } from 'react'
import Image from 'next/image'
import { SOCKET_URL,ICON_URL } from '@/core/constants/urls'
import Text from '@/components/common/utils/Text'
import { useRouter } from 'next/router'
import { formatNumber } from '@/core/utils/common'
import { BiChevronLeft } from 'react-icons/bi'
import { Flex, IconWrapper } from '@/styles/styled'
import LINK from '@/components/common/utils/LINK'
import { HiArrowDown, HiArrowUp } from 'react-icons/hi'
import styled from 'styled-components'
import { MainButton } from '@/components/common/buttons/MainButton'
import { FaStar } from 'react-icons/fa'

const RMarketTable = ({ coins, currency, prime, onFav }) => {
    const { locale, push } = useRouter()

    const [limitCoins, setLimitCoins] = useState(7)

    const handleLimitCoinsChange = () => {
        setLimitCoins((currentLimit) => (currentLimit === 7 ? coins.length : 7))
    }

    const seeMoreBtnText =
        limitCoins === 7 ? (
            <>
                <Text tid='see-more' />
                <HiArrowDown />
            </>
        ) : (
            <>
                <Text tid='see-less' />
                <HiArrowUp />
            </>
        )

    return (
        <div className={'flex flex-col mt-5 gap-2'}>
            {coins.map((coin, idx) => {
                let change
                try {
                    change = parseFloat(coin.changes['24h'].changePresent)
                } catch (_) {}

                if (idx < limitCoins || prime) {
                    return (
                        <div
                            className={'market-resp-card fontBold'}
                            onClick={() => push(coin.id)}>
                            <div
                                className={
                                    'flex justify-start items-center w-[140px]'
                                }>
                                <span
                                    className={`${
                                        coin.fav
                                            ? 'text-yellow-500'
                                            : 'text-gray-500 dark:text-gray-dark'
                                    } cursor-pointer me-2`}
                                    onClick={(e) => {
                                        e.stopPropagation()
                                        onFav(coin.id)
                                    }}>
                                    <FaStar size={14} />
                                </span>
                                <Image
                                    alt={coin.id}
                                    width={20}
                                    height={20}
                                    src={
                                        ICON_URL +
                                        `assets/icon/${coin.id}.png`
                                    }
                                />
                                <div className={'flex gap-x-2 ms-2'}>
                                    <Text
                                        tid={coin.fa}
                                        className={
                                            'hidden sm:block sm:text-sm md:text-sm'
                                        }
                                    />
                                    <Text
                                        tid={coin.name}
                                        className={'text-xs md:text-sm'}
                                    />
                                </div>
                            </div>
                            <div
                                className={
                                    'flex flex-col items-start w-[175px]'
                                }>
                                <div
                                    className={
                                        'flex justify-center items-center flex-wrap'
                                    }>
                                    <Text
                                        tid={'buy-price'}
                                        className={'text-xs'}
                                    />{' '}
                                    :
                                    <Text
                                        tid={formatNumber(
                                            currency === 'tether'
                                                ? coin.value
                                                : coin.buy,
                                            {
                                                type:
                                                    currency === 'tether'
                                                        ? coin.id
                                                        : 'irt',
                                                coin: coin.id,
                                            }
                                        )}
                                        className={'text-xs mx-1'}
                                    />
                                </div>
                                <div
                                    className={
                                        'flex justify-center items-center flex-wrap'
                                    }>
                                    <Text
                                        tid={'sell-price'}
                                        className={'text-xs'}
                                    />{' '}
                                    :
                                    <Text
                                        tid={formatNumber(
                                            currency === 'tether'
                                                ? coin.value
                                                : coin.sell,
                                            {
                                                type:
                                                    currency === 'tether'
                                                        ? coin.id
                                                        : 'irt',
                                                coin: coin.id,
                                            }
                                        )}
                                        className={'text-xs mx-1'}
                                    />
                                </div>
                            </div>
                            <div dir={'ltr'} className={'w-[49px]'}>
                                <Text
                                    tid={`${change} %`}
                                    className={`text-${
                                        change >= 0 ? 'success' : 'danger'
                                    } text-xs`}
                                />
                            </div>
                            <LINK href={`${coin.id}`}>
                            <IconWrapper>
                                <BiChevronLeft size={22} />
                            </IconWrapper>
                        </LINK>
                        </div>
                    )
                }
            })}

            {!prime && (
                <Flex fw margin={'2rem 0 0'}>
                    <MoreButton onClick={handleLimitCoinsChange}>
                        {seeMoreBtnText}
                    </MoreButton>
                </Flex>
            )}
        </div>
    )
}

const MoreButton = styled(MainButton)`
    display: flex;
    align-items: center;
    gap: 1rem;
    padding: 1rem;
    font-size: 0.75rem;
    color: ${(props) => props.theme.color};
`

export default RMarketTable
