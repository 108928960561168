import styled from 'styled-components'
import Text from '@/components/common/utils/Text'

const MarketsBtn = ({ children, tid, ...rest }) => {
    return (
        <Wrapper {...rest}>
            <Text tid={tid} />
        </Wrapper>
    )
}

const Wrapper = styled.div`
    /* width: 80%; */
    margin-inline-end: 1rem;
    padding: 0.5rem;
    border-radius: 8px;
    /* background-color: ${(props) => props.theme.mainBtnBg}; */
    cursor: pointer;
    transition: all 0.2s;

    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
        /* background-color: ${(props) => props.theme.marketBtnHover}; */
    }

    span {
        @media screen and (max-width: 1024px) {
            font-size: 0.6rem;
        }
    }
`

export default MarketsBtn
