import { useState } from 'react'

let tradeModeState // Declare a shared state variable outside the hook

export const useTradeMode = (type = 'buy') => {
    const [tradeMode, setTradeMode] = useState(type)

    // Initialize shared state only once
    if (!tradeModeState) {
        tradeModeState = useState(type)
    }

    const setSharedTradeMode = (tradeMode) => {
        tradeModeState[1](tradeMode)
        setTradeMode(tradeMode)
    }

    return [tradeModeState[0], setSharedTradeMode] // Return shared state and setter
}
